import { useState } from 'react';
import { ExtensionOutlined } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, CardContent, Typography, Link } from '@mui/material';
import { useNavigation, useNotification } from '@refinedev/core';
import { AxiosError } from 'axios';

import { Dropzone } from '@attackiq/components';
import { useBoolean } from '@attackiq/hooks';

import { uploadFile } from '../../utils/uploadFile';

type useBooleanType = [boolean, { on: () => void; off: () => void }];

const ConnectorCreate: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);
  const [isUploading, { on: setIsUploadingStarted, off: setIsUploadingFinished }] = useBoolean(false) as useBooleanType;
  const { list } = useNavigation();

  const { open } = useNotification();

  const handleSubmit = async () => {
    if (!selectedFile) {
      return;
    }

    try {
      setIsUploadingStarted();
      await uploadFile(selectedFile, '/v1/connectors', 'tar_file', 'post');
      list('connectors');
    } catch (error) {
      open?.({
        type: 'error',
        message: (error as AxiosError).response?.data?.detail
      });
    } finally {
      setIsUploadingFinished();
    }
  };

  return (
    <Card>
      <Box sx={{ p: 2 }}>
        <CardHeader title={<Typography variant="h5">Upload connector</Typography>}></CardHeader>
        <CardContent>
          <Dropzone
            rules={{
              accept: '.tar.gz'
            }}
            onDropAccepted={files => {
              setSelectedFile(files[0]);
            }}
            width="100%"
            height={152}
          >
            <Dropzone.Body>
              <>
                <Typography>
                  <Link component="span">Click to upload</Link> or drag and drop
                </Typography>
                <Typography color="text.secondary">.tar.gz files supported only</Typography>
              </>
            </Dropzone.Body>

            <Dropzone.Cover
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              bgcolor="background.paper"
              in={!!selectedFile}
            >
              <ExtensionOutlined color="primary" />
              <Typography>{selectedFile?.name}</Typography>
            </Dropzone.Cover>
          </Dropzone>
          <Button sx={{ mt: 2 }} onClick={handleSubmit} disabled={isUploading} variant="contained">
            Upload connector
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ConnectorCreate;
