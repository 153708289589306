// import { Layout } from './components/layout';
import { PropsWithChildren } from 'react';
import {
  Assessment as AssessmentIcon,
  Extension as ExtensionIcon,
  FileUploadOutlined,
  History as HistoryIcon,
  Inventory as InventoryIcon,
  LocalOffer as LocalOfferIcon,
  MovieCreation as MovieCreationIcon,
  PieChart as PieChartIcon,
  ViewModule as ViewModuleIcon
} from '@mui/icons-material';
import { CssBaseline, GlobalStyles, Box } from '@mui/material';
import { Refine } from '@refinedev/core';
import { RefineKbarProvider, RefineKbar } from '@refinedev/kbar';
import { RefineSnackbarProvider, Layout, ReadyPage, notificationProvider } from '@refinedev/mui';
import routerProvider from '@refinedev/react-router-v6/legacy';

import AttackIQTitleComponent from './components/AttackIQTitleComponent';
import Header from './components/Header/Header';
import { ColorModeContextProvider } from './contexts';
import { AssessmentTemplateEdit, AssessmentTemplateList } from './pages/assessment_templates';
import { CloudProvidersCreate, CloudProvidersEdit, CloudProvidersList } from './pages/cloud_providers';
import { ConnectorCreate, ConnectorEdit, ConnectorList } from './pages/connectors';
import { ReadyDocumentationList } from './pages/files';
import { FlexPackagesCreate, FlexPackagesEdit, FlexPackagesList } from './pages/flex_packages';
import { HistoryList } from './pages/history';
import { MitigationList, MitigationsCreate } from './pages/mitigations';
import MitigationsEdit from './pages/mitigations/edit';
import { ModuleContentCreate, ModuleContentList } from './pages/module_contents';
import { ModuleCreate, ModuleEdit, ModuleList, ModuleShow } from './pages/modules';
import { ParameterModelerList } from './pages/parameter_modeler';
import { PhaseEdit, PhaseList } from './pages/phases';
import { ReportTypeCreate, ReportTypeEdit, ReportTypeList } from './pages/report_types';
import { ScenarioCreate, ScenarioEdit, ScenarioList } from './pages/scenarios';
import { TaggedItemsCreate, TaggedItemsList } from './pages/tagged_items';
import { TagCreate, TagList } from './pages/tags';
import TagsEdit from './pages/tags/edit';
import { TagSetsCreate, TagSetsEdit, TagSetsList } from './pages/tagsets';
import { ScenarioTemplateList, ScenarioTemplateEdit } from './pages/templates';
import { VendorProductCreate, VendorProductEdit, VendorProductList } from './pages/vendor_products';
import { VendorCreate, VendorEdit, VendorList } from './pages/vendors';
import { NewCreate, NewEdit, NewList, NewShow } from './pages/whats_new';
import authProvider from './providers/authProviders';
import httpClient from './utils/axios';
import drfDataProvider from './utils/drfDataProvider';

const API_URL = '/v1';

const defaultDataProvider = drfDataProvider(API_URL, httpClient);

const SidebarIcon: React.FC<PropsWithChildren<Record<never, never>>> = ({ children }) => (
  <Box
    sx={{
      color: 'gray',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {children}
  </Box>
);

const NoIcon = () => null;

const OffLayoutArea: React.FC = () => {
  return <RefineKbar />;
};

function App() {
  return (
    <ColorModeContextProvider>
      <RefineKbarProvider>
        <RefineSnackbarProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
          <Refine
            legacyRouterProvider={routerProvider}
            notificationProvider={notificationProvider}
            Layout={Layout}
            Header={Header}
            ReadyPage={ReadyPage}
            Title={AttackIQTitleComponent}
            OffLayoutArea={OffLayoutArea}
            disableTelemetry
            legacyAuthProvider={authProvider}
            reactQueryDevtoolConfig={false} // TODO: Using "@pankod/refine-core": "^3.58.1" introduces a terrible performance issue when we use the devtool.
            resources={[
              // ---- MODULES SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <ViewModuleIcon />
                  </SidebarIcon>
                ),
                name: '_modules'
              },
              {
                parentName: '_modules',
                icon: <NoIcon />,
                name: 'modules',
                create: ModuleCreate,
                edit: ModuleEdit,
                list: ModuleList,
                show: ModuleShow,
                canDelete: true
              },
              {
                parentName: '_modules',
                icon: <NoIcon />,
                name: 'module_contents',
                list: ModuleContentList,
                create: ModuleContentCreate
              },
              // ---- ASSESSMENTS SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <AssessmentIcon />
                  </SidebarIcon>
                ),
                name: 'assessments'
              },
              {
                parentName: 'assessments',
                icon: <NoIcon />,
                name: 'assessment_templates',
                list: AssessmentTemplateList,
                edit: AssessmentTemplateEdit
              },
              // ---- REPORTS SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <PieChartIcon />
                  </SidebarIcon>
                ),
                name: 'reports'
              },
              {
                parentName: 'reports',
                icon: <NoIcon />,
                name: 'report_types',
                edit: ReportTypeEdit,
                create: ReportTypeCreate,
                list: ReportTypeList
              },
              // ---- SCENARIOS SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <MovieCreationIcon />
                  </SidebarIcon>
                ),
                name: 'content',
                meta: {
                  label: 'Scenarios'
                }
              },
              {
                parentName: 'content',
                icon: <NoIcon />,
                name: 'scenarios',
                create: ScenarioCreate,
                list: ScenarioList,
                edit: ScenarioEdit
              },
              {
                parentName: 'content',
                icon: <NoIcon />,
                name: 'mitigations',
                list: MitigationList,
                edit: MitigationsEdit,
                create: MitigationsCreate
              },
              {
                parentName: 'content',
                icon: <NoIcon />,
                name: 'scenario_templates',
                meta: {
                  label: 'Templates'
                },
                list: ScenarioTemplateList,
                edit: ScenarioTemplateEdit
              },
              {
                parentName: 'content',
                icon: <NoIcon />,
                name: 'phases',
                list: PhaseList,
                edit: PhaseEdit
              },
              {
                parentName: 'content',
                icon: <NoIcon />,
                name: 'cloud_providers',
                list: CloudProvidersList,
                edit: CloudProvidersEdit,
                create: CloudProvidersCreate
              },
              // ---- INTEGRATIONS SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <ExtensionIcon />
                  </SidebarIcon>
                ),
                name: 'integrations'
              },
              {
                parentName: 'integrations',
                icon: <NoIcon />,
                name: 'connectors',
                list: ConnectorList,
                create: ConnectorCreate,
                edit: ConnectorEdit
              },
              {
                parentName: 'integrations',
                icon: <NoIcon />,
                name: 'vendors',
                list: VendorList,
                create: VendorCreate,
                edit: VendorEdit
              },
              {
                parentName: 'integrations',
                icon: <NoIcon />,
                name: 'vendor_products',
                list: VendorProductList,
                create: VendorProductCreate,
                edit: VendorProductEdit
              },
              {
                parentName: 'integrations',
                icon: <NoIcon />,
                name: 'parameter_modeler',
                list: ParameterModelerList,
                meta: {
                  label: 'Parameter modeler (legacy)'
                }
              },
              // ---- TAGGING SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <LocalOfferIcon />
                  </SidebarIcon>
                ),
                name: 'tagging',
                meta: {
                  label: 'Tagging'
                }
              },
              {
                icon: <NoIcon />,
                parentName: 'tagging',
                name: 'tags',
                list: TagList,
                edit: TagsEdit,
                create: TagCreate
              },
              {
                icon: <NoIcon />,
                parentName: 'tagging',
                name: 'tag_sets',
                meta: {
                  label: 'Tagsets'
                },
                list: TagSetsList,
                edit: TagSetsEdit,
                create: TagSetsCreate
              },
              {
                icon: <NoIcon />,
                parentName: 'tagging',
                name: 'tagged_items',
                meta: {
                  label: 'Tagged Items'
                },
                list: TaggedItemsList,
                create: TaggedItemsCreate
              },
              // ---- FLEX PACKAGES SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <InventoryIcon />
                  </SidebarIcon>
                ),
                name: 'flex',
                meta: {
                  label: 'Flex'
                }
              },
              {
                icon: <NoIcon />,
                parentName: 'flex',
                name: 'flex_packages',
                meta: {
                  label: 'Flex Packages'
                },
                list: FlexPackagesList,
                create: FlexPackagesCreate,
                edit: FlexPackagesEdit,
                canDelete: true
              },
              // ---- AUDIT SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <HistoryIcon />
                  </SidebarIcon>
                ),
                name: 'history',
                list: HistoryList,
                meta: {
                  label: 'History'
                }
              },
              // ---- NEWS SECTION ----
              {
                icon: (
                  <SidebarIcon>
                    <ViewModuleIcon />
                  </SidebarIcon>
                ),
                name: '_news'
              },
              {
                parentName: '_news',
                icon: <NoIcon />,
                name: 'whats_new',
                meta: {
                  label: "What's new"
                },
                create: NewCreate,
                edit: NewEdit,
                list: NewList,
                show: NewShow,
                canDelete: true
              },
              {
                icon: (
                  <SidebarIcon>
                    <FileUploadOutlined />
                  </SidebarIcon>
                ),
                name: '_files'
              },
              {
                parentName: '_files',
                icon: <NoIcon />,
                name: 'ready_documentation',
                meta: {
                  label: 'Ready Docs'
                },
                list: ReadyDocumentationList,
                canDelete: true
              }
            ]}
            dataProvider={defaultDataProvider}
          />
        </RefineSnackbarProvider>
      </RefineKbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
