import { useEffect, useState } from 'react';
import { DocumentScannerOutlined } from '@mui/icons-material';
import { Box, Link, Skeleton, Typography } from '@mui/material';
import { useCreate, useMany } from '@refinedev/core';

import { Dropzone } from '@attackiq/components';
import { STATIC_DOCUMENTATION_IDS } from '@attackiq/constants';

const fields = [
  { title: 'Test point Installation Guidance', id: STATIC_DOCUMENTATION_IDS.TEST_POINT_INSTALLATION_INSTRUCTIONS },
  { title: 'White-listing Guidance', id: STATIC_DOCUMENTATION_IDS.WHITE_LISTING_GUIDANCE },
  {
    title: 'Email Retrieval Configuration Guidance',
    id: STATIC_DOCUMENTATION_IDS.EMAIL_RETRIEVAL_CONFIGURATION_GUIDANCE
  },
  { title: 'WAF Testing Guidance', id: STATIC_DOCUMENTATION_IDS.WAF_TESTING_GUIDANCE }
];

export const ReadyDocumentationList = () => {
  const { data: response, isLoading } = useMany({
    resource: 'files',
    ids: fields.map(f => f.id)
  });

  return (
    <>
      <Typography variant="h6">Ready Documentation Files</Typography>
      <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: `repeat(auto-fit, minmax(300px, 1fr))`, gap: 4 }}>
        {fields.map((field, index) => (
          <Box key={field.id}>
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height={150} />
            ) : (
              <>
                <Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
                  {field.title}
                </Typography>
                <DocumentationUpload
                  id={field.id}
                  existingFileName={response?.data?.[index]?.file_name.split('/').pop()}
                />
              </>
            )}
          </Box>
        ))}
      </Box>
    </>
  );
};

const DocumentationUpload = ({ existingFileName, id }: { existingFileName?: string; id: string }) => {
  const { mutate, isLoading: isUpdating } = useCreate();

  const [selectedFile, setSelectedFile] = useState<File | undefined | null>(null);

  useEffect(() => {
    if (existingFileName) {
      setSelectedFile(new File([], existingFileName));
    }
  }, [existingFileName]);

  const select = file => {
    setSelectedFile(file);

    const formData = new FormData();
    formData.append('file', file);

    mutate({
      values: formData,
      resource: `files/${id}/upload_ready`,
      metaData: {
        axiosConfigOverride: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      }
    });
  };

  return (
    <Dropzone
      rules={{
        accept: 'application/pdf'
      }}
      onDropAccepted={files => {
        select(files[0]);
      }}
      width="100%"
      height={152}
      isLoading={isUpdating}
    >
      <Dropzone.Body>
        <>
          <Typography>
            <Link component="span">Click to upload</Link> or drag and drop
          </Typography>
          <Typography color="text.secondary">PDF files supported only (max. 3MB)</Typography>
        </>
      </Dropzone.Body>

      <Dropzone.Cover
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        bgcolor="background.paper"
        in={!!selectedFile}
      >
        <DocumentScannerOutlined color="primary" />
        <Typography>{selectedFile?.name}</Typography>
      </Dropzone.Cover>
    </Dropzone>
  );
};

export default ReadyDocumentationList;
