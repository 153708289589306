import { useState } from 'react';
import { ExtensionOutlined } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { useInvalidate, useNavigation, useNotification, useResource } from '@refinedev/core';
import { Edit } from '@refinedev/mui';

import { Dropzone } from '@attackiq/components';

import httpClient from '../../utils/axios';

const ConnectorEdit = () => {
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { id, resourceName } = useResource();

  const { open } = useNotification();

  const { list } = useNavigation();

  const invalidate = useInvalidate();

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const url = `/v1/${resourceName}/${id}`;

      const formData = new FormData();

      formData.append('tar_file', selectedFile as File);
      await httpClient.put(url, formData);

      open?.({
        message: 'Connector updated successfully',
        type: 'success'
      });

      invalidate({
        resource: resourceName,
        invalidates: ['all']
      });
      // @ts-ignore: add description to ignore
      list(resourceName);
    } catch (e) {
      open?.({
        // @ts-ignore: add description to ignore
        message: e.response.data.detail,
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Edit
      isLoading={isLoading}
      saveButtonProps={{
        onClick: handleSubmit
      }}
    >
      <Box component="form" onSubmit={handleSubmit} autoComplete="off">
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Upload a new connector to replace this one
        </Typography>
        <Dropzone
          rules={{
            accept: '.tar.gz'
          }}
          onDropAccepted={files => {
            setSelectedFile(files[0]);
          }}
          width="100%"
          height={152}
        >
          <Dropzone.Body>
            <>
              <Typography>
                <Link component="span">Click to upload</Link> or drag and drop
              </Typography>
              <Typography color="text.secondary">.tar.gz files supported only</Typography>
            </>
          </Dropzone.Body>

          <Dropzone.Cover
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            bgcolor="background.paper"
            in={!!selectedFile}
          >
            <ExtensionOutlined color="primary" />
            <Typography>{selectedFile?.name}</Typography>
          </Dropzone.Cover>
        </Dropzone>
      </Box>
    </Edit>
  );
};

export default ConnectorEdit;
